import Pristine from "pristinejs";
import "./lib/jquery.maskedinput";

export default function modals() {

    (function inputPhoneMask() {
        $('input[type="tel"]').mask("+7 (999) 999-99-99");
      })();


    (function formValidation() {
        const formList = document.querySelectorAll("[data-form-validate]");

        formList.forEach((form) => {
          const validation = new Pristine(form);
          const successMessage = form.querySelector(".form-success__message")
          form.addEventListener("submit", function (e) {
            e.preventDefault();
            const formData = new FormData(this);
            const valid = validation.validate();
            successMessage.style.display = "none"

            if (valid) {
              $.ajax({
                // dataType: "json",
                type: form.method,
                url: form.action,
                data: formData,
                cache: false,
                processData: false,
                contentType: false,
                success() {
                    form.reset();
                    successMessage.innerHTML = 'Ваша заявка успешно отправлена!';
                    successMessage.style.display = "block";
                },
                error() {},
              });
            }
          });
        });
      })();

}

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// eslint-disable-next-line import/prefer-default-export
export function sliders() {

  const simpleSlider = () => {
    const swiper = new Swiper(".hero__slider", {
      modules: [Pagination, Autoplay],
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      spaceBetween: 23,
      watchOverflow: true,
      pagination: {
        el: '.hero__slider .swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        640: {
          slidesPerView: 1
        },
        1024: {
          slidesPerView: 1.5
        },
        2000: {
          slidesPerView: 2
        }
      }
    });
  }

  const setSliders = () => {
    document.querySelectorAll('.box-slider').forEach((el, i) => {
      const sliderClass = `box-slider-${i}`
      let sliderType = Number(el.dataset.sliderType)
      if(!sliderType) sliderType = 0
      el.classList.add(sliderClass)
      let slidesPV = 1
      if(sliderType === 1){
        slidesPV = 3
      } else if(sliderType === 2 || sliderType === 3){
        slidesPV = 2
      }
      const sliderOptions = {
        modules: [Navigation, Pagination],
        slidesPerView: slidesPV,
        spaceBetween: 20,
        navigation: {
          nextEl: `.${sliderClass} .box-slider__navigation-next`,
          prevEl: `.${sliderClass} .box-slider__navigation-prev`
        }
      }
      if(sliderType === 0){
        sliderOptions.breakpoints = {
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1360: {
            slidesPerView: 4
          }
        }
      } else if(sliderType === 2){
        sliderOptions.breakpoints = {
          640: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 4
          },
          1360: {
            slidesPerView: 6
          }
        }
      } else if(sliderType === 3){
        sliderOptions.breakpoints = {
          640: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 4
          }
        }
      }

      const slider = new Swiper(`.${sliderClass} .swiper`, sliderOptions)
    })
  }

  simpleSlider()
  setSliders()
}

/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";
import 'fslightbox'; // eslint-disable-line
import "easy-autocomplete"; // eslint-disable-line
import Choices from "choices.js"; // eslint-disable-line

export default function controls() {

  const filterToggle = document.querySelector('.catalog-filter__toggle');
  const filterClose = document.querySelector('.page-sidebar__close');
  const filterWrapper = document.querySelector('#catalog-sidebar');

  const toggleFilter = () => {
    if (filterWrapper.classList.contains('fixed')) {
      filterWrapper.classList.remove('fixed');
    } else {
      filterWrapper.classList.add('fixed');
    }
  }
  if (filterToggle) {
    filterToggle.addEventListener('click', (e) => {
      e.preventDefault();
      toggleFilter();
    });
  }
  if (filterClose) {
    filterClose.addEventListener('click', (e) => {
      e.preventDefault();
      if (filterWrapper) {
        filterWrapper.classList.remove('fixed');
      }
    });
  }

  document.querySelectorAll('[data-choice]').forEach(el => {
    const singleNoSearch = new Choices(el, {
      allowHTML: true,
      itemSelectText: '',
      searchEnabled: false,
    });
  })

  const mobileMenu = document.querySelector('.mmenu')
  const mobileMenuMask = document.querySelector('.mmenu__mask')

  const toggleMenu = () => {
    mobileMenu.classList.toggle('mmenu__opened')
    mobileMenuMask.classList.toggle('opened')
  }

  document.querySelectorAll( 'a[href="#mmenu"]' ).forEach(el => {
    el.addEventListener( 'click', ( e ) => {
      e.preventDefault()
      toggleMenu()
    });
  })

  mobileMenuMask.addEventListener('click', () => {
    toggleMenu()
  })

  document.querySelector('.mmenu__close').addEventListener('click', () => {
    toggleMenu()
  })

  document.querySelectorAll('[data-choice-search]').forEach(el => {
    const singleNoSearch = new Choices(el, {
      allowHTML: true,
      itemSelectText: '',
      searchEnabled: true,
    });
  })


  $(".form-search input[type='text']").each(function() {
    const $item = $(this)


  const jsonUrl = $item.data("url");
  // console.log(jsonUrl);
  $item.easyAutocomplete({
    url (phrase) {
      return `${jsonUrl}?phrase=${phrase}&format=json`;
    },
    getValue: "name",
    cssClasses: "search-head",
    template: {
      type: "custom",
      method(value, item) {
        return `<a href='${item.uri}' ><span class='search-img'><img src='${item.icon}' /></span><span class='search-name'>${value}</span><span class='search-cost'>${item.cost}</span></a>`;
      },
    },
    list: {
      maxNumberOfElements: 10,
      match: {
        enabled: false,
      },
      sort: {
        enabled: true,
      },
    },
  });

  $item.closest('form').on("submit", function (e) {
    e.preventDefault();
    window.location = `/search?q=${$item.val()}`;
  });
});

  class QuantityCounter {
    constructor(element) {
      this.el      = element;
      this.count   = 1;

      this.init();
    }

    init() {
      this.inputEl = this.el.querySelector('[data-counter-input]');
      this.plusEl  = this.el.querySelector('[data-counter-plus]');
      this.minusEl = this.el.querySelector('[data-counter-minus]');

      this.count = Number.parseInt(this.inputEl.value, 10);

      this.plusEl.addEventListener('click', (e) => this.plus());
      this.minusEl.addEventListener('click', (e) => this.minus());
      this.inputEl.addEventListener('change', (e) => this.update(e.target.value));

      this.update();
    }

    plus() {
      this.count += 1;
      this.update();
    }

    minus() {
      this.count -= 1;
      this.update();
    }

    update(val) {
      if (val) this.count = Number.parseInt(val, 10);
      const limit = this.count <= 999;
      this.count = (this.count >= 1 && limit) ? this.count : 1;
      this.inputEl.value = this.count;

      if (!val) {
        this.inputEl.dispatchEvent(new Event('change'));
      }
    }


  }

  const counters = document.querySelectorAll('.quantity-counter');
  counters.forEach(el => new QuantityCounter(el));


    (function sidebarNavToggle() {
      const toggleClass       = 'sidebar-nav__toggle';
      const parentClass       = 'sidebar-nav__item';
      const itemClassActive = `${parentClass}_active`;
      const groupElements   = document.querySelectorAll(`.${toggleClass}`);

      // console.log(groupElements);

      groupElements.forEach(el => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          // const parentEl = el.closest(`.${parentClass}`);
          // if (parentEl) {
          //   parentEl.classList.toggle(itemClassActive);
          // }
        })
      })

    })();



    (function() {
      const productMainImage = document.querySelector('.product-image__main-image')
      $('.product-image__thumbs-item').on('click', function() {
        $('.product-image__thumbs-item').removeClass('active');
        this.classList.add('active');
        productMainImage.src = this.querySelector('img').src;
      })
    })();
}
